import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import './module.css';
import { CONFIG } from 'utils/config';

// importing Images
import MpnPng from '../images/mpn_card.png';
import DmiPng from '../images/dmi_card.png';
import UgmPng from '../images/ugm_card.png';

interface ModuleWithoutConfig {
  // id ensures every known module must be defined:
  id: keyof typeof CONFIG.modules;
  title: string;
  image: string;
  description: string;
}
interface ModuleDetail extends ModuleWithoutConfig {
  url: string;
  enabled: boolean;
  visible: boolean;
}
type ModuleDetails = Array<ModuleDetail>;

const Module = () => {
  const modules: Array<ModuleWithoutConfig> = [
    {
      id: 'mpn',
      title: 'Mine Performance Navigator',
      image: MpnPng,
      description:
        'In-depth analysis of historical and forecast data, regardless of the data collection tools used on-site.',
    },
    {
      id: 'dmi',
      title: 'DiiMOS Modelling Interface',
      image: DmiPng,
      description: 'Powerful business driver modelling for rapid scenario modelling.',
    },
    {
      id: 'ugm',
      title: 'Underground Manager',
      image: UgmPng, // fixme: need offical ugm image
      description:
        'Create a scenario config and trigger simulations on parameters of that scenario to future plan changes of resources to your site.', // needs better text
    },
  ];
  const gridData: ModuleDetails = modules
    .map((module) => ({
      ...module,
      ...CONFIG.modules[module.id],
    }))
    .filter((module) => module.visible);
  return (
    <Grid item xs={12}>
      <Grid container justifyContent="flex-start" spacing={3}>
        {gridData.map(({ id, title, image, description, url, enabled }) => (
          <Grid item key={id}>
            <Card className="tile-card disabled" sx={{ width: 421, height: 386 }}>
              <CardActionArea
                sx={{ width: 421, height: '85%' }}
                onClick={() => window.open(url, '_blank', 'noopener,noreferrer')}
              >
                <CardMedia component="img" height="200" image={image} alt={title} />
                <CardContent>
                  <div className="heading">{title}</div>
                  <Typography className="textLabel">{description}</Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button
                  sx={{ backgroundColor: 'unset !important' }}
                  size="large"
                  disabled={!enabled}
                  onClick={() => window.open(url, '_blank', 'noopener,noreferrer')}
                  endIcon={<LaunchIcon sx={{ width: 18, ml: 1 }} />}
                >
                  Launch
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default Module;
